.card {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 30px;
  border-radius: 15px;
}

/* Desktop */
@media (min-width: 550px) {
  .inCardContainer {
    display: flex;
  }
  
  .inCardContainer > div:first-child {
    /* Set flex-basis to auto to make the first div size to its content */
    flex: 0 0 auto;
  }
  
  .inCardContainer > div:last-child {
    /* Set flex-grow to 1 to make the second div fill the remaining space */
    flex: 1 1 0;
  }
}


/* Mobile */
@media (max-width: 550px) {
  .inCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
  }
  
  .inCardContainer > div {
    /* Styles for the div elements within the container */
    margin-top: 25px;
  }

  .inCardContainer > div:last-child {
    display: none;
  }
}

