/* General */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

/* Desktop */
@media (min-width: 700px) {

}

/* Mobile */
@media (max-width: 700px) {
  .container {
    flex-direction: column; /* Stack the divs vertically */
    align-items: flex-start; /* Align items to the start of the container */
  }

  .container > div {
    padding-bottom: 20px;
  }
}