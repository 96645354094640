.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  width: 100%; /* Set the width of the imageContainer to 100% */
  height: 100%; /* Set the height of the imageContainer to 100% */
}

.box {
  flex: 0 0 calc(45%); /* Three boxes per row with 20px spacing */
  padding: 10px;
  margin: 10px;
}

.image {
  width: 100%;
  height: "auto";
  border: 1px solid #f5f6fb;
  border-radius: 15px;
}


/* Desktop */
@media (min-width: 600px) {
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .container > div {
    min-width: 0; /* Allow the div to shrink when needed */
  }
  
  .firstDiv {
    flex: 0 0 60%; /* Take 60% of the available width */
  }
  
  .secondDiv {
    flex: 0 0 30%; /* Take 30% of the available width */
  }
}

/* Mobile */
@media (max-width: 600px) {
  .container {
    flex-direction: column; /* Display divs one below the other */
  }
}