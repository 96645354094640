.carousel {
  overflow: hidden; /* Hide any content that overflows the carousel container */
  /* max-width: 1360px; */
  margin: auto;
}

.mask {
  display: flex; /* Use flexbox to horizontally align the slider items */
  overflow-x: scroll; /* Allow horizontal scrolling for the slider items */
  overflow-y: hidden; /* Disable vertical scrolling */
  scroll-snap-type: x mandatory; /* Enable snap points for smooth scrolling to each slider item */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS devices */

  /* Hide the scrollbar to improve visual appearance */
  scrollbar-width: none; /* Hide the scrollbar for Firefox */
  
}

/* Hide the scrollbar for WebKit browsers (Chrome and Safari) */
.mask::-webkit-scrollbar {
  display: none;
}

/* == buttons - START == */
.buttons {
  width: 100%;
  position: relative;
}

.buttons button {
  background: white;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonLeft {
  position: absolute;
  top: 75px;
  left: 0;
  z-index: 4;
  padding-inline-start: 15px;
}

.buttonRight {
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 4;
  padding-inline-end: 5px;
}
/* == buttons - END == */


/* Desktop */
@media (min-width: 600px) {

  .carousel {
    /* width: 800px; */
  }

}

/* Mobile */
@media (max-width: 600px) {

  .carousel {
    width: 100vw;
  }

  .mask {
    padding-left: 20px;
  }

}