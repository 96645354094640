/* General */
.parentContainer {
  padding: 20px 30px;
  max-width: 100%;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.box {
  flex: 0 0 calc(33.33% - 20px); /* Three boxes per row with 20px spacing */
  min-width: 300px; /* Set a minimum width for each box */
  padding: 10px;
  margin: 10px;
}

.card {
  background: #ffffff;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
  box-shadow: 1px 1px 15px -1px #e9e9e9;
}

/* Desktop */
@media (min-width: 600px) {

  .card {
    margin: 20px;
    padding: 20px;
  }

}

/* Mobile */
@media (max-width: 600px) {

}