.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2px;
}

.image {
  grid-row: span 2; /* Make the image div span two rows */
}

.title {
  grid-column: 2; /* Move the Title div to the second column */
  align-self: end; /* Align the Title div to the bottom of its grid cell */
}

.link {
  grid-column: 2; /* Move the Link div to the second column */
}

.imageShadow {
  box-shadow: 1px 1px 10px -1px #e9e9e9;
  margin: 5px 15px;
  padding: 10px;
}