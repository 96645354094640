.itemsContainer {
  padding: 20px 30px;
}

.image {
  width: 80%;
  max-width: "500px";
  height: "auto";
  border: 1px solid #f5f6fb;
  border-radius: 15px;
}