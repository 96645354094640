/* == Elements - START =========================== */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #011e37;
}

strong {
  font-weight: 600;
}
/* == Elements - END ============================= */

/* == General - START ============================ */
.firstBackground {
  background: #ffffff;
}

.secondBackground {
  background: #f5f6fb;
}

.width100 {
  width: 100%;
}

.maxWidth {
  max-width: 1360px;
}

.marginAuto{ 
  margin: auto;
}

.primaryPadding {
  padding: 15px 25px;
}
/* == General - END ============================== */

/* == Margin - START ============================= */
.marginVertical {
  margin: auto 0px;
}
/* == Margin - END =============================== */

/* == Separator - START ========================== */
.separatorEmptySmall {
  height: 7px;
}

.separatorEmptyMedium {
  height: 15px;
}

.separatorEmptyLarge {
  height: 25px;
}

.separatorVerticalGray {
  width: 2px;
  margin: 10px 20px;
  background: #f0f1f7;
}

.separatorVerticalEmpty {
  width: 60px;
}

.separatorVerticalEmptySmall {
  width: 20px;
}

.separatorVerticalEmptyVerySmall {
  width: 10px;
}

.separatorVerticalEmptySuperSmall {
  width: 5px;
}

.dividerHorizontal {
  height: 1px;
  background: #e2e4ef;
}
/* == Separator - END ============================ */

/* == Cards - START ============================== */
.cardGray {
  background: #f5f6fb;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 30px;
  border-radius: 15px;
}

.cardShadow {
  border: 1px solid #f0f1f7;
  box-shadow: 3px 3px 24px -7px rgba(151, 151, 151, 0.42);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 30px;
  border-radius: 15px;
}
/* == Cards - END ================================ */

/* == Fonts - START ============================== */
.orangeFont {
  color: #ff7800;
}

.fontGray {
  color: #767b87;
}

.darkBlueFont {
  color: #011e37;
}

.capitalizeFirstLetter {
  text-transform: capitalize;
}

.fontSizeCaption {
  font-size: 0.75rem;
}

.fontReceived {
  color: #0eaf6b;
}

.fontSmall {
  font-size: small;
}

.fontMedium {
  font-size: medium;
}

.fontLarger {
  font-size: larger;
}

.fontXLarger {
  font-size: x-large;
}

.fontBold {
  font-weight: bold;
}

.fontUnderline {
  text-decoration: underline;
}

.textAlignCenter {
  text-align: center;
}
/* == Fonts - END ================================ */

/* == Links - START ============================== */
a:visited {
  color: #011e37;
}

.linkDarkBlue, .linkDarkBlue:visited {
  color: #011e37;
}
/* == Links - END ================================ */

/* == Forms - START ============================== */
/* CSS for styling invalid fields */
.invalidInput {
  background: #ffe9e7 !important; /* Add a red border to invalid fields */
  border-radius: 4px;
}
/* == Forms - END ================================ */

/* == Buttons - START ============================ */
.pointer {
  cursor: pointer;
}
/* == Buttons - END ============================== */

/* == Images - START ============================= */
.squareLogo {
  width: 40px;
  height: 40px;
}

.imageShadowGray {
  box-shadow: 1px 1px 10px -1px #e9e9e9;
}
/* == Images - END =============================== */

/* == Containers - START ========================= */
.containerFlex {
  display: flex;
}

.flexCenterHorizontally {
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.alignItemsCenter {
  align-items: center;
}

.flexEnd {
  justify-content: flex-end;
}
/* == Containers - END =========================== */

/* == Progress Bar - START ======================= */
.progressContainer {
  height: 10px;
  background: #f5f6fb;
  border: 1px solid #fafbfd;
  border-radius: 6px;
  overflow: hidden; /* Hide overflowing content */
}

.progressContainerWidth200 {
  width: 200px;
}

.progressBar {
  height: 100%;
  background: #1bd687;
  transition: width 0.3s ease-in-out;
}
/* == Progress Bar - END ========================= */

/* == Loading - START ============================ */
@keyframes loading-dots {
  0%, 20% {
    transform: scale(1);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: scale(1);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dot {
  width: 10px;
  height: 10px;
  background-color: #ff7800;
  border-radius: 50%;
  margin: 0 3px;
  animation: loading-dots 1s infinite ease-in-out;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.17s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.34s;
}
/* == Loading - END ============================== */

/* == DragAndDrop - START ======================== */
.dragBorder {
  border: 2px dashed #d2dbe6;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
}
/* == DragAndDrop - END ========================== */

/* == Override - START =========================== */
.css-w0qwtg.Mui-active {
  color: #ff7800 !important;
}

.whiteIcon {
  color: white !important;
}
/* == Override - END ============================= */

/* == Responsive - START ========================= */
/* Desktop */
@media (min-width: 600px) {

}

/* Mobile */
@media (max-width: 600px) {
  .titleImage {
    max-width: 300px;
    /* min-height: 30px; */
    padding-left: 15px;
  }
}
/* == Responsive - END =========================== */

.companysNotFound {
  text-align: center;
  font-size: 20px;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}

.companyWalletProgress .MuiLinearProgress-bar{
  background-color: #21c27e !important;
}

.companyWalletProgress .MuiLinearProgress-root{
  background-color: #d2dbe6 !important;
}

/* .desktop-vertical-stepper .MuiStepConnector-line{
  min-height: 22px !important;
} */

.desktop-vertical-stepper .MuiStepLabel-root{
  padding: 0 !important;
}

.desktop-vertical-stepper .Mui-active svg{
  color: #FF7800 !important;
}

.mobile-vertical-stepper .Mui-active svg{
  color: #FF7800 !important;
}
.passsword-modal-height .MuiPaper-root {
  height: 50vh;
}
.about-company-dialog .MuiPaper-root{
  height: 100%;
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .submit-btn{
    font-size: 12px !important;
  }
  .declaration-heading{
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1208px) {
  .submit-btn{
    font-size: 12px !important;
  }
}

.accredited-form-check label{
  align-items: start !important;
}
.accredited-form-check label span {
  padding-top: 0 !important;
  text-align: justify !important;
}