/* General */
.container {
  display: flex;
  justify-content: space-between;
}

.container > div:first-child {
  float: left;
}

.container > div:nth-child(2) {
  margin: auto;
}

.container > div:last-child {
  float: right;
  margin-top: auto;
  margin-bottom: auto;
}


/* Desktop */
@media (min-width: 700px) {
  .container {
    width: 100vw;
  }
  .logoContainer {
    padding: 15px 25px
  }
}

/* Mobile */
@media (max-width: 700px) {
  .logoContainer {
    margin: auto;
  }

  .logo {
    height: 44px;
  }
}