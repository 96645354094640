/* General */
.container {
  background: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.content {
  padding: 20px 40px;
}

.threeImages {
  width: 320px;
  max-width: 80vw;
}

.containerParagraphsAndImage {
  display: flex;
}

.centerImage {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.containerThreeImages {
  display: flex;
  justify-content: center; /* Center items horizontally */
  flex-wrap: wrap;
}

.imageBox {
  flex: 0 0 calc(33.33% - 20px); /* Three boxes per row with 20px spacing */
  min-width: 320px; /* Set a minimum width for each box */
  padding: 5px;
  margin: 0px;
}

/* Desktop */
@media (min-width: 600px) {

  .centerImage {
    padding-right: 30px;
  }

  .mainImage {
    width: 500px;
    max-width: 90vw;
    text-align: center;
  }

}

/* Mobile */
@media (max-width: 600px) {

  .containerParagraphsAndImage {
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
  }

  .mainImage {
    width: 320px;
    max-width: 80vw;
    text-align: center;
  }

}