.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  width: 100%; /* Set the width of the imageContainer to 100% */
  height: 100%; /* Set the height of the imageContainer to 100% */
}

.box {
  flex: 0 0 calc(45%); /* Three boxes per row with 20px spacing */
  padding: 10px;
  margin: 10px;
}

.image {
  width: 100%;
  height: "auto";
  border: 1px solid #f5f6fb;
  border-radius: 15px;
}