/* Desktop */
@media (min-width: 550px) {
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .container > div:first-child {
    float: left;
  }
  
  .container > div:last-child {
    float: right;
  }
}

/* Mobile */
@media (max-width: 550px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
  }

  .container > span {
    /* Styles for the div elements within the container */
    margin-top: 15px;
  }
}