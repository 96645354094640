/* General */
.assistantBold {
  font-weight: 500;
}

.assistantText {
  font-weight: 300;
}

/* Desktop */
@media (min-width: 700px) {
  .container {
    background: #011e37;
    color: white;
    padding: 10px 15px;
    border-radius: 15px;
    font-size: 15px;
  }

  .containerDesktop {
    display: flex;
    justify-content: space-between;
  }
  
  .containerDesktop > div:first-child {
    float: left;
  }

  .containerDesktop > div:nth-child(2) {
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .containerDesktop > div:last-child {
    float: right;
    margin-top: auto;
    margin-bottom: auto;
  }

  .assistantImage {
    width: 48px;
    height: 48px;
  }
}


/* Mobile */
@media (max-width: 700px) {
  .container {
    background: #011e37;
    color: white;
    padding: 7px 10px;
    border-radius: 15px;
  }

  .containerMobile {
    display: flex;
    justify-content: space-between;
  }

  .containerMobile > div:nth-child(2) {
    flex: 1;
  }

  .assistantImage {
    width: 32px;
    height: 32px;
  }
}