/* Styles for the carousel container */
.carousel {
  width: 100vw; /* Set the width of the carousel to be 100% of the viewport width */
  overflow: hidden; /* Hide any content that overflows the carousel container */
  max-width: 1360px;
  margin: auto;
}

/* Styles for the carousel mask, which contains the slider items */
.mask {
  display: flex; /* Use flexbox to horizontally align the slider items */
  overflow-x: scroll; /* Allow horizontal scrolling for the slider items */
  overflow-y: hidden; /* Disable vertical scrolling */
  scroll-snap-type: x mandatory; /* Enable snap points for smooth scrolling to each slider item */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS devices */

  /* Hide the scrollbar to improve visual appearance */
  scrollbar-width: none; /* Hide the scrollbar for Firefox */
}

/* Hide the scrollbar for WebKit browsers (Chrome and Safari) */
.mask::-webkit-scrollbar {
  display: none;
}

/* Styles for each individual slider item */
.slider {
  display: flex; /* Use flexbox to horizontally align the slider items */
  scroll-snap-align: start; /* Align the slider items to the start of the snap area */
}

/* Styles for the images inside each slider item */
.slider img {
  width: 160px; /* Set a fixed width for the images */
  height: auto; /* Automatically adjust the height to maintain aspect ratio */
  scroll-snap-align: center; /* Align the images to the center of the snap area */
}

.articleContainer {
  position: relative;
  width: 200px;
  height: 200px;
  margin-right: 20px; /* Add right margin between articles */
}

.articleImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.articleTitle {
  position: absolute;
  bottom: 0;
  left: 0;

  max-width: 200px; /* Set a maximum width for the text container */
  padding: 8px; /* Add padding to the text */
  
  background-color: rgba(1, 30, 55, 0.7);
  color: #fff;
  text-align: center;
  font-size: 14px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.buttons {
  width: 100%;
  position: relative;
}

.buttons button {
  background: white;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonLeft {
  position: absolute;
  top: 75px;
  left: 0;
  z-index: 4;
  padding-inline-start: 15px;
}

.buttonRight {
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 4;
  padding-inline-end: 5px;
}

/* Desktop */
@media (min-width: 700px) {
  /* Apply left margin to the first article to create space on the left */
  .articleContainer:first-child {
    margin-left: 20px;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .articleContainer:first-child {
    margin-left: 10px;
  }
}