/* Desktop */
@media (min-width: 600px) {
  .container {
    display: flex;
    justify-content: space-between;
  }

  .container > div:first-child {
    float: left;
  }

  .container > div:nth-child(2) {
    margin: auto;
  }

  .container > div:last-child {
    float: right;
    margin-top: auto;
    margin-bottom: auto;
  }
}


/* Mobile */
@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
  }
  
  .container > div {
    /* Styles for the div elements within the container */
    margin-top: 15px;
  }
}