.container {
  display: flex;
}

.cardWrap {
  cursor: pointer;
}

.title {
  color: #021f38;
  font-weight: 600;
  text-decoration: underline;
}

.subTitle {
  font-size: small;
  color: #868b95;
}

.separatorVerticalGray {
  width: 2px;
  margin: 5px 10px;
  background: #e2e4ef;
}

.actionLink {
  color: #011e37;
  font-size: small;
}

.iconAndLinkContainer {
  display: flex;
  align-items: center;
  color: #011e37;
}

.iconAndLinkContainer:visited {
  color: #011e37;
}