.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: stretch; /* Make child elements have the same height */
}

.box {
  flex: 0 0 calc(20% - 20px); /* Three boxes per row with 20px spacing */
  min-width: 240px; /* Set a minimum width for each box */
  min-height: 240px;
  padding: 10px;
  margin: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally within the card */

  background: #ffffff;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
  box-shadow: 1px 1px 15px -1px #e9e9e9;
  margin: 20px;
  padding: 20px;
}

.topImage {
  width: 150px; /* Set the width of the image as desired */
  height: auto; /* Set the height of the image as desired */
}

.contentText {
  text-align: center;
}