.card {
  position: relative;
  width: 500px;
  height: auto;
  margin-right: 20px; /* Add right margin between articles */
  min-width: 265px;
}

.cardImage {
  display: flex;
}

.cardImage img {
  height: 160px;
  width: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.cardDetails {
  background: #f5f6fb;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 15px;
}

