.card {
  padding: 15px 25px 30px;
  border-radius: 15px;
}

/* Shared styles for both desktop and mobile */
.container {
  display: flex;
  justify-content: space-between;
}

.container > div {
  flex: 1; /* Equal width for both divs */
  padding-inline-end: 40px;
}

/* Desktop */
@media (min-width: 550px) {
  .container > div {
    float: none; /* Remove float from both divs */
  }
}

/* Mobile */
@media (max-width: 550px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .container > div {
    margin-top: 25px;
    text-align: left;
  }
}