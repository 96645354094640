/* General */
.container {
  width: 100%;
  background: #011e37;
  color: #ffffff;
  padding: 15px 0px;
}

.container > div > div:first-child {
  color: #d8d8d8;
}

/* Desktop */
@media (min-width: 600px) {
  .container {
    display: flex;
  }
  
  .container > div {
    flex: 1;
    text-align: center;
  }
}


/* Mobile */
@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
  }
  
  .container > div {
    /* Styles for the div elements within the container */
    margin-top: 15px;
    text-align: center;
  }
}