.card {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 30px;
  border-radius: 15px;
}

.inCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
}

.inCardContainer > div {
  /* Styles for the div elements within the container */
  margin-top: 25px;
}
