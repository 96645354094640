/* General */
.companyCard {
  background: white;
  border-radius: 14px;
  padding: 17px 15px;
  cursor: pointer;
  min-width: 250px;
}

.companyCardActive {
  border: 2px solid #011e37;
}

/* Desktop */
@media (min-width: 700px) {

}

/* Mobile */
@media (max-width: 700px) {
  
  /* Styles for the carousel container */
  .carousel {
    width: 100vw; /* Set the width of the carousel to be 100% of the viewport width */
    overflow: hidden; /* Hide any content that overflows the carousel container */
  }

  /* Styles for the carousel mask, which contains the slider items */
  .mask {
    display: flex; /* Use flexbox to horizontally align the slider items */
    overflow-x: scroll; /* Allow horizontal scrolling for the slider items */
    scroll-snap-type: x mandatory; /* Enable snap points for smooth scrolling to each slider item */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS devices */

    /* Hide the scrollbar to improve visual appearance */
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
  }

  /* Hide the scrollbar for WebKit browsers (Chrome and Safari) */
  .mask::-webkit-scrollbar {
    display: none;
  }

  /* Styles for each individual slider item */
  .slider {
    display: flex; /* Use flexbox to horizontally align the slider items */
    scroll-snap-align: start; /* Align the slider items to the start of the snap area */
  }

  /* Styles for the images inside each slider item */
  /* .slider img {
    width: 160px; 
    height: auto; 
    scroll-snap-align: center; 
  } */
  
}