.mainContainer {
  display: flex;
  justify-content: center;
  background-color: #061f35;
  padding: 16px;
  width: 50%;
  margin: auto;
  margin-top: 15px !important;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentContainer {
  margin: auto;
  width: 40%;
}
/* @media (max-width: 767px) {
  .mainContainer {
    width: 60%;
  }
  .contentContainer {
    width: 50% !important;
  }
} */
@media screen and (min-width: 320px) and (max-width: 500px) {
  .mainContainer {
    width: 87%;
  }
  .contentContainer {
    width: 90% !important;
  }
  #submit-btn{
    font-size: 13px !important;
  }
}
@media screen and (min-width: 501px) and (max-width: 768px) {
  .mainContainer {
    width: 87%;
  }
  .contentContainer {
    width: 65% !important;
  }
 
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mainContainer {
    width: 65%;
  }
  .contentContainer {
    width: 40% !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .mainContainer {
    width: 50%;
  }
  .contentContainer {
    width: 35% !important;
  }
}